// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// @import '~@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// #343A40  Toolbard-background from IDP
// #fcce10
$alko-primary-red: #e30613;
$alko-primary-grey: #4b565d;
$alko-primary-black: #000000;
$alko-primary-white: #ffffff;

$alko-neutral-lightgrey: #f4f4f4;
$alko-neutral-lightblue: #f0f5f7;
$alko-neutral-grey30: #c9ccce;
$alko-neutral-grey20: #dbdddf;
$alko-neutral-grey10: #edeeef;

// $alko-claim: #4b565d;
$alko-default-font: #4b565d;
$alko-default-background: #ffffff;
$alko-second-background: #f2f2f2; // für Hintergründe (SEO, PopUps, u.a.)
// $alko-blocks: #F0F5F7; // für Blöcke, Sidebars, Category-Header
$alko-background-menu: #4f5456;
$alko-hover-boxes: #898989; // Invertierte Hover Boxen: Hintergrund grau #898989 mit weißer Schrift #fff, wenn mit der Maus ein Hover über Teaser erzeugt wird (Startseiten)

$md-test: (
  50: #00ff00,
  100: #00ff00,
  200: #00ff00,
  300: #00ff00,
  400: #00ff00,
  500: #00ff00,
  600: #00ff00,
  700: #00ff00,
  800: #00ff00,
  900: #00ff00,
  A100: #00ff00,
  A200: #00ff00,
  A400: #00ff00,
  A700: #00ff00,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-alkored: (
  50: #fce1e3,
  100: #f7b4b8,
  200: #f18389,
  300: #eb515a,
  400: #e72b36,
  500: #e30613,
  600: #e00511,
  700: #dc040e,
  800: #d8030b,
  900: #d00206,
  A100: #fff8f8,
  A200: #ffc5c6,
  A400: #ff9293,
  A700: #ff7979,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-alkodarkgrey: (
  50: #b6bbbe,
  100: #96a0a7,
  200: #7e8c95,
  300: #677984,
  400: #5b6a73,
  500: #4b565d,
  600: #3c4348,
  700: #2b2f31,
  800: #2b2f31,
  900: #2b2f31,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$alko-background: (
  // ... your other background colors ...
  card: #00ff00,
  // Change this to your desired shade of green
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// $dashboard-primary: mat.define-palette($alko-primary-red);
// $dashboard-primary: mat.define-palette(mat.$indigo-palette);
$dashboard-primary: mat.m2-define-palette($md-alkored);
$dashboard-accent: mat.m2-define-palette($md-alkodarkgrey);

// The warn palette is optional (defaults to red).
$dashboard-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dashboard-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $dashboard-primary,
      accent: $dashboard-accent,
      warn: $dashboard-warn,
      buxdehude: $dashboard-warn,
      // background: $alko-background, // Include the background palette here
      // background: (
      //   card: $alko-second-background,
      // ),
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($dashboard-theme);

// .alko-card {
//   background-color: #00FF00 !important; // funktioniert
// }

