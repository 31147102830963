@use "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@use "./alko-theme.scss";
@use "../node_modules/material-symbols/index.css";
@use "./custom-theme.scss" as custom-theme;

@font-face {
  font-family: "OpenSans-Regular";
  src: url("/assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans-condensed";
  src: url("/assets/fonts/OpenSans_Condensed-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans-Condensed-light";
  src: url("/assets/fonts/OpenSans_Condensed-Light.ttf") format("truetype");
  font-weight: 300; // Light
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Condensed-bold";
  src: url("/assets/fonts/OpenSans_Condensed-Bold.ttf") format("truetype");
  font-weight: bold; // Bold
  font-style: normal;
}

// @font-face {
//   font-family: "Material Icons";
//   font-style: normal;
//   font-weight: 400;
//   src:
//     local("Material Icons"),
//     local("MaterialIcons-Regular"),
//     url("/assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Material Icons";
//   font-style: outlined;
//   font-weight: 400;
//   src: url("/assets/fonts/MaterialIconsOutlined-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Material Icons";
//   font-style: round;
//   font-weight: 400;
//   src: url("/assets/fonts/MaterialIconsRound-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Material Icons";
//   font-style: sharp;
//   font-weight: 400;
//   src: url("/assets/fonts/MaterialIconsSharp-Regular.ttf") format("truetype");
// }

// .material-icons {
//   font-family: "Material Icons";
//   font-weight: normal;
//   font-style: normal;
//   font-size: 24px;
//   line-height: 1;
//   letter-spacing: normal;
//   text-transform: none;
//   display: inline-block;
//   white-space: nowrap;
//   word-wrap: normal;
//   direction: ltr;
//   -webkit-font-feature-settings: "liga";
//   -webkit-font-smoothing: antialiased;
// }

html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-family: "OpenSans-Regular", sans-serif;
}

// body .mat-mdc-card {
//   border: 1px solid $alko-neutral-grey30;
//   box-shadow: none;

//   // height: 100%;
//   // align-items: space-between;
//   // justify-content: stretch;
// }

.full-width {
  width: 100%;
  max-width: 100%;
}

.alko-form-field {
  margin-top: 10px;
}

.full-height {
  height: 100%;
}

.alko-card-header {
  // background-color: brown;
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

mat-card-header {
  padding-top: 12px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
mat-card-content {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 12px !important;
}

mat-card-header {
  p {
    margin-bottom: 4px;
  }
}

mat-dialog-content {
  background-color: custom-theme.$alko-neutral-lightblue;
  // background-color: $alko-neutral-lightblue;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.centered-text {
  text-align: center;
}

.primary-action-button-icon {
  color: white !important;
}

.primary-action-button {
  margin-right: 5px;
}
